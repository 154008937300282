import Images from '../../../assets/Images'
import Icon from '../../../assets/Icon'
import Utils from '../../utils'

class AvailableLocationUiController {

    constructor({ globalStore, parentContainer }) {

        this.globalStore = globalStore
        this.parentContainer = parentContainer

    }

    makePackageItem(item) {
        let container = document.createElement('div')
        container.classList.add('availableLocation-section')
        container.innerHTML = `
            <div class='availableLocation-section-img'>
                <img src='${item.fromImage}'/>
            </div>    
            <div class='availableLocation-section-details'>
                <span>Chennai</span>    
                <span>Comming Soon</span>    
            </div>    
        `
        return container
    }

    renderPackagesItems() {
        let Items = [

            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },

            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
        ]

        let itemsParent = this.container.querySelector('.availableLocation-sections')

        itemsParent.innerHTML = ``

        Items.forEach((itm, i) => {

            let container = this.makePackageItem(itm)
            itemsParent.appendChild(container)
        })
    }


    makeContainer() {
        let container = document.createElement('div')
        container.classList.add('project-availableLocation-main')
        container.innerHTML = `
            <div class='availableLocation-sections-bg'></div>
            <div class='availableLocation-items'>
                <div class='availableLocation-title-main'>
                    ${Icon.borderleft}
                    <div class='availableLocation-title'>
                        Available <span>Locations</span>
                    </div>
                    ${Icon.borderright}
                </div>               
                <div class='availableLocation-desc'>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo jhbum.</p>
                </div>               
                <div class='availableLocation-sections' id='availableLocation-sections'>

                </div>             
            </div>               
        `

        return container
    }



    addEvent() {
        this.renderPackagesItems()

        Utils.drag_ScrollVertical('availableLocation-sections')
    }
    show() {

        this.container = this.makeContainer()
        this.parentContainer.appendChild(this.container)
        this.addEvent()
    }


}

export default AvailableLocationUiController;