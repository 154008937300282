class Store {
    constructor() {

        this.name = "globalStore"

    }
    add(key, value, forced = false) {

        if ((key in this) && !forced) throw Error('Key is already in store')

        this[key] = value
    }
    hasKey(key) {

        if (key in this) return true
        add
        return false
    }

}

export default Store;
