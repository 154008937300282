
import Images from '../../../assets/images'

class PopupUiController {

  constructor({ globalStore, parentContainer }) {
    this.globalStore = globalStore
    this.parentContainer = parentContainer
  }

  makeContainer() {
    let container = document.createElement('div')
    container.classList.add('project-popup-ui-block')
    container.innerHTML = `

            <div class='blockui-mask'></div>
            <div class='popup-container-main'>
                <div class='popup-container-close' id='popup-container-close'>
                    <i class='bx bx-x'></i>
                </div>

                <div class="popup-banner-carousel">
                  <ul class="popup-banner-slides">

                    <input type="radio" name="radio-buttons" id="img-1" checked />
                    <li class="popup-banner-slide-container">
                      <div class="popup-banner-slide-image">
                        <img src="${Images.popup_banner_1}">
                      </div>
                      <div class="popup-banner-carousel-controls">
                        <label for="img-2" class="popup-banner-prev-slide">
                          <span>&lsaquo;</span>
                        </label>
                        <label for="img-2" class="popup-banner-next-slide">
                          <span>&rsaquo;</span>
                        </label>
                      </div>
                    </li>

                    <input type="radio" name="radio-buttons" id="img-2" />
                    <li class="popup-banner-slide-container">
                      <div class="popup-banner-slide-image">
                        <img src="${Images.popup_banner_2}">
                      </div>
                      <div class="popup-banner-carousel-controls">
                        <label for="img-1" class="popup-banner-prev-slide">
                          <span>&lsaquo;</span>
                        </label>
                        <label for="img-1" class="popup-banner-next-slide">
                          <span>&rsaquo;</span>
                        </label>
                      </div>
                    </li>
                    
                    <div class="popup-banner-carousel-dots">
                      <label for="img-1" class="popup-banner-carousel-dot" id="img-dot-1"></label>
                      <label for="img-2" class="popup-banner-carousel-dot" id="img-dot-2"></label>
                     
                    </div>
                  </ul>
                    
                </div>
            </div>
        `

    return container
  }
  hide() {
    if (this.parentContainer.querySelector('.project-popup-ui-block')) this.parentContainer.removeChild(this.container)
  }
  addEvent() {
    let close_btn = this.container.querySelector('#popup-container-close')

    close_btn.addEventListener('click', (e) => {
      this.hide()
    })
  }
  show() {

    this.container = this.makeContainer()
    this.parentContainer.appendChild(this.container)
    this.addEvent()
  }


}

export default PopupUiController;