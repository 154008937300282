import Images from '../../../assets/Images'
import Icon from '../../../assets/Icon'
import Utils from '../../utils'

class TestimonialUiController {

    constructor({ globalStore, parentContainer }) {

        this.globalStore = globalStore
        this.parentContainer = parentContainer

    }

    makePackageItem(item) {
        let container = document.createElement('div')
        container.classList.add('testimonial-section')
        container.innerHTML = `
            <div class='testimonial-section-icon-top'>${Icon.quotetop}</div>
            
            <div class='testimonial-section-details'>
                <div class='testimonial-section-details-left'>
                    <div class='testimonial-section-img'>
                        <img src='${Images.customerApp}'/>
                    </div>
                </div>   
                <div class='testimonial-section-details-right'>

                    <div class='testimonial-section-details-answer'>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                        sed diam nonumy eirmod tempor invidunt ut labore et dolore 
                        magna aliquyam erat, sed diam voluptua. At vero eos et accusam 
                        et justo duo dolores et ea rebum.
                    </div>    
                    <div class='testimonial-section-details-customer'>- Ezio Auditore</div>    
                </div>    
            </div>    
             
            <div class='testimonial-section-icon-bottom'>${Icon.quotebottom}</div>

        `
        return container
    }

    renderPackagesItems() {
        let Items = [

            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },

        ]

        let itemsParent = this.container.querySelector('.testimonial-sections')

        itemsParent.innerHTML = ``

        Items.forEach((itm, i) => {

            let container = this.makePackageItem(itm)
            itemsParent.appendChild(container)
        })
    }


    makeContainer() {
        let container = document.createElement('div')
        container.classList.add('project-testimonial-main')
        container.innerHTML = `
            <div class='testimonial-items'>
                            
                <div class='testimonial-sections' id='testimonial-sections'>

                <section class="carousel">
                <div class="carousel_slider">
                  <div class="content_inner_slider">

                    <div class='testimonial-section'>
                      <div class='testimonial-section-icon-top'>${Icon.quotetop}</div>
                      <div class='testimonial-section-details'>
                          <div class='testimonial-section-details-left'>
                              <div class='testimonial-section-img'>
                                  <img src='${Images.customerApp}'/>
                              </div>
                          </div>   
                          <div class='testimonial-section-details-right'>
                              <div class='testimonial-section-details-answer'>
                                Best and less expensive cab service for Chennai. Recently tried their service when Namma ooru taxi and UBER were trying to charge me around Rs.850 for a ride. But, the same ride costed me just Rs.430 in Namma Ooru Taxi. The cab arrived on time and Driver was polite and friendly as well. If you are being charged unusually high by Namma ooru taxi and UBER give Namma Ooru Taxi a try and travel for a lot less. There are few bugs in their app. Hope it gets fixed. However, highly recommended for cab service in Chennai. 👍
                              </div>    
                              <div class='testimonial-section-details-customer'>- Chakkravarthi Raghavan</div>    
                          </div>    
                      </div>    
                      <div class='testimonial-section-icon-bottom'>${Icon.quotebottom}</div>
                    </div>
                    <div class='testimonial-section'>
                      <div class='testimonial-section-icon-top'>${Icon.quotetop}</div>
                      <div class='testimonial-section-details'>
                          <div class='testimonial-section-details-left'>
                              <div class='testimonial-section-img'>
                                  <img src='${Images.customerApp}'/>
                              </div>
                          </div>   
                          <div class='testimonial-section-details-right'>
                                <div class='testimonial-section-details-answer'>
                                    Good app.... I saw more app for travel... But no one ready to my time.... Finally I will choose Namma ooru taxi... It was on time move to my destination.... Definitely next time I will give preference to Namma ooru taxi.
                                </div>    
                                <div class='testimonial-section-details-customer'>- Stephan</div>    
                          </div>    
                      </div>    
                      <div class='testimonial-section-icon-bottom'>${Icon.quotebottom}</div>
                    </div>
                    <div class='testimonial-section'>
                      <div class='testimonial-section-icon-top'>${Icon.quotetop}</div>
                      <div class='testimonial-section-details'>
                          <div class='testimonial-section-details-left'>
                              <div class='testimonial-section-img'>
                                  <img src='${Images.customerApp}'/>
                              </div>
                          </div>   
                          <div class='testimonial-section-details-right'>
                              <div class='testimonial-section-details-answer'>
                                Really the best app for cab.Price is too low. When compared to all other cab like Namma ooru taxi. This is the best one. Don't missed it.
                              </div>    
                              <div class='testimonial-section-details-customer'>- Mohan Raja</div>    
                          </div>    
                      </div>    
                      <div class='testimonial-section-icon-bottom'>${Icon.quotebottom}</div>
                    </div>
                    <div class='testimonial-section'>
                      <div class='testimonial-section-icon-top'>${Icon.quotetop}</div>
                      <div class='testimonial-section-details'>
                          <div class='testimonial-section-details-left'>
                              <div class='testimonial-section-img'>
                                  <img src='${Images.customerApp}'/>
                              </div>
                          </div>   
                          <div class='testimonial-section-details-right'>
                              <div class='testimonial-section-details-answer'>
                              Good price,but please develop some future ,and local trips not pick any one drivers ,outstation is very fast responce ..i hope your future is best growing.thank you
                              </div>    
                              <div class='testimonial-section-details-customer'>- Manoj Kumar</div>   
                          </div>    
                      </div>    
                      <div class='testimonial-section-icon-bottom'>${Icon.quotebottom}</div>
                    </div>
                    <div class='testimonial-section'>
                      <div class='testimonial-section-icon-top'>${Icon.quotetop}</div>
                      <div class='testimonial-section-details'>
                          <div class='testimonial-section-details-left'>
                              <div class='testimonial-section-img'>
                                  <img src='${Images.customerApp}'/>
                              </div>
                          </div>   
                          <div class='testimonial-section-details-right'>
                                <div class='testimonial-section-details-answer'>
                                    Quick pickup,Great service and comfortable price,Excellent app with cheap rates. Better than Namma ooru taxi
                                </div>    
                                <div class='testimonial-section-details-customer'>- Mani Kandan</div>    
                          </div>    
                      </div>    
                      <div class='testimonial-section-icon-bottom'>${Icon.quotebottom}</div>
                    </div>
                
                    
                  </div>
                  <button class="prev_button" style='transform: rotate(180deg);' title='Previous'>
                        ${Icon.arrowright}
                  </button>
                  <button class="next_button" title='Next'>
                    ${Icon.arrowright}
                  </button>
                </div>
                <ul class="dots">
                  <li class="dot" value="0"></li>
                  <li class="dot" value="1"></li>
                  <li class="dot" value="2"></li>
                  <li class="dot" value="3"></li>
                  <li class="dot" value="4"></li>
                </ul>
                
              </section>


                </div>             
            </div>               
        `

        return container
    }



    addEvent() {
        // this.renderPackagesItems()

        // Utils.drag_ScrollVertical('testimonial-sections')
    }
    show() {

        this.container = this.makeContainer()
        this.parentContainer.appendChild(this.container)
        this.addEvent()
    }


}

export default TestimonialUiController;