import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx'
let ratio;
let utils = {

    aerialHeight: 20,
    unitDirection: {
        MAXDISTANCE: 500, // in meters
        MINDISTANCE: 10,
        MODDISTANCE: 50
    },
    checkImage: (url) => {
        let request = new XMLHttpRequest();
        request.open("GET", url, true);
        request.send();
        request.onload = function () {
            if (request.status == 200) return true
            else return false

        }
    },
    lerp: (x, y, a) => x * (1 - a) + y * a,
    // Slerp: (x, y, a) => (y * math.inv(x))**a * x,
    clamp: (a, min = 0, max = 1) => Math.min(max, Math.max(min, a)),
    invlerp: (x, y, a) => utils.clamp((a - x) / (y - x)),

    sleep: async function (time) {

        return new Promise(resolve => setTimeout(resolve, time))

    },
    formatNumber: (number, decimals = 2) => {

        let nums = String(number || 0).split('.')

        return parseInt(nums[1]) > 0 ? parseFloat(nums.join('.')).toFixed(decimals || 2) : nums[0]

    },

    drag_ScrollVertical(id) {
        if (!id) return
        const container = document.getElementById(id);

        let startY;
        let startX;
        let scrollLeft;
        let scrollTop;
        let isDown;

        container.addEventListener('mousedown', e => mouseIsDown(e));
        container.addEventListener('mouseup', e => mouseUp(e))
        container.addEventListener('mouseleave', e => mouseLeave(e));
        container.addEventListener('mousemove', e => mouseMove(e));

        function mouseIsDown(e) {
            isDown = true;
            startY = e.pageY - container.offsetTop;
            startX = e.pageX - container.offsetLeft;
            scrollLeft = container.scrollLeft;
            scrollTop = container.scrollTop;
        }
        function mouseUp(e) {
            isDown = false;
        }
        function mouseLeave(e) {
            isDown = false;
        }
        function mouseMove(e) {
            if (isDown) {
                e.preventDefault();
                //Move vertcally
                const y = e.pageY - container.offsetTop;
                const walkY = y - startY;
                container.scrollTop = scrollTop - walkY;

                //Move Horizontally
                const x = e.pageX - container.offsetLeft;
                const walkX = x - startX;
                container.scrollLeft = scrollLeft - walkX;

            }
        }
    },
    exportTableToPDF(title, tabel, style) {

        // CREATE A WINDOW OBJECT.
        var win = window.open('', '', 'height=1000,width=1000');

        let content = `
            <html>
                <head>
                    <title>${title}</title>
                    ${style}
                </head>
                <body>
                    <table id='manage-officerides-tabel' class='manage-rides-tabel-container'>
                        ${tabel}
                    </table>
                </body>
            </html>
        `

        win.document.write(content);
        // win.document.write('<html><head>');
        // win.document.write(`<title>${title}</title>`);   // <title> FOR PDF HEADER.
        // win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
        // win.document.write('</head>');
        // win.document.write('<body>');
        // win.document.write(`<table id='manage-officerides-tabel' class='manage-rides-tabel-container'>`);
        // win.document.write(tabel);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
        // win.document.write(`</table>`);
        // win.document.write('</body></html>');

        win.document.close();
        // win.print();
    },

    processData(TableHeader, Tabledata) {
        let dataStruct = {};
        const csvData = [];
        for (let i = 0; i < Tabledata.length; i += 1) {
            for (let j = 0; j < TableHeader.length; j += 1) {
                const { label, name } = TableHeader[j];
                if (label.toLowerCase() !== 'id') {
                    if (Array.isArray(Tabledata[i][name])) {
                        dataStruct[label] = Tabledata[i][name].join(',');
                    } else {
                        dataStruct[label] = Tabledata[i][name];
                    }
                }
            }
            csvData.push(dataStruct);
            dataStruct = {};
        }
        return csvData;
    },
    excelToJSON(file, callback) {

        let conver = (file) => {

            if (!file) return

            let fileReader = new FileReader();

            fileReader.onload = function (event) {

                let data = event.target.result;
                let workbook = XLSX.read(data, {
                    type: "binary"
                });

                let xl_data = workbook.SheetNames.map(sheet => {
                    return XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
                });

                if (xl_data) callback(xl_data)
            }

            fileReader.readAsBinaryString(file);

        }

        conver(file)

    },

    exportTableToExcel(TableHeader, Tabledata) {

        const csvData = this.processData(TableHeader, Tabledata);
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        const reader = new FileReader();
        reader.onload = (e) => {
            const link = document.createElement('a');
            link.download = 'document';
            link.href = e.target.result;
            link.click();
        };
        reader.readAsDataURL(data);
    },
    Slerp: function ({ time, location, movement, }) {

        const TRACK = movement.track;

        if (time) {

            let { startTime, endTime, hoverTime } = time;
            let duration = endTime - startTime;
            let cursorTime = hoverTime - startTime;

            let interol = cursorTime / duration * 100;
            let _totalDistance = 0

            TRACK.forEach((ar, i) => {
                if (i != TRACK.length - 1)
                    _totalDistance += this.calculateDistanceBetwwenPoints(TRACK[i][0], TRACK[i][1], TRACK[i + 1][0], TRACK[i + 1][1])
            })

            let distance = interol * _totalDistance / 100
            let _per_d = 0;
            let latlng = []

            for (let _i = 0; _i < TRACK.length; _i++) {

                if (_i == TRACK.length - 1) return
                _per_d += this.calculateDistanceBetwwenPoints(TRACK[_i][0], TRACK[_i][1], TRACK[_i + 1][0], TRACK[_i + 1][1])
                if (_per_d > distance) {
                    let interpolationValue = (_per_d - distance) / _totalDistance;
                    interpolationValue = interpolationValue > 1 ? 1 : interpolationValue
                    interpolationValue = interpolationValue < 0 ? 0 : interpolationValue
                    let lat = this.lerp(TRACK[_i][0], TRACK[_i + 1][0], interpolationValue)
                    let lng = this.lerp(TRACK[_i][1], TRACK[_i + 1][1], interpolationValue)
                    latlng = [lat, lng];
                    break;
                }
            }

            return latlng;

        }
        else if (location) {

            let _totalDistance = 0
            let _per_d = []
            let __per_d = 0
            const _lDis = []

            TRACK.forEach((ar, i) => {
                if (i == TRACK.length - 1) return;

                let mlatlng = [(TRACK[i][0] + TRACK[i + 1][0]) / 2, (TRACK[i][1] + TRACK[i + 1][1]) / 2]

                _totalDistance += this.calculateDistanceBetwwenPoints(TRACK[i][0], TRACK[i][1], TRACK[i + 1][0], TRACK[i + 1][1]);
                _lDis.push(this.calculateDistanceBetwwenPoints(mlatlng[0], mlatlng[1], location[0], location[1]))
                __per_d += this.calculateDistanceBetwwenPoints(TRACK[i][0], TRACK[i][1], TRACK[i + 1][0], TRACK[i + 1][1])
                _per_d.push(__per_d)
            })

            let li = _lDis.indexOf(Math.min(..._lDis))
            let dPer = ((_per_d[li == 0 ? li : li - 1] + Math.min(..._lDis)) / _totalDistance) * 100
            let time = movement.startTime + ((dPer * (movement.endTime - movement.startTime)) / 100)

            return time;
        }

    },
    getCirclePoints: function (centre, rad, angle) {
        // Formula for calculate points => Math.cos and sin functions take radians..so, convert angle
        // into radians and apply it

        let point_x = centre[0] + rad * window.Math.cos(angle * (window.Math.PI / 180))
        let point_y = centre[1] + rad * window.Math.sin(angle * (window.Math.PI / 180))

        return [point_x, point_y];
    }
    ,

    rotatePoint: function (origin, coord, angle) {

        let [cx, cy] = origin
        let [x, y] = coord

        let radians = (Math.PI / 180) * angle

        let cos = Math.cos(radians)
        let sin = Math.sin(radians)

        let nx = (cos * (x - cx)) + (sin * (y - cy)) + cx
        let ny = (cos * (y - cy)) - (sin * (x - cx)) + cy

        return [nx, ny];
    },

    rotatePointArray: function (origin, coords, angle) {
        let points = []
        coords.forEach(coord => {

            let point = utils.rotatePoint(origin, coord, angle)
            points.push(point)

        })

        return points
    },

    rotatePositionArray: function (origin, coords, angle) {

        let points = []

        for (let i = 0; i < coords.length; i += 2) {

            let lon = coords[i]
            let lat = coords[i + 1]

            let point = utils.rotatePoint(origin, [lon, lat], angle)
            points.push(...point)

        }

        return points
    },

    calculateSlope: function (p1, p2) {

        let yDiff = p2[1] - p1[1]
        let xDiff = p2[0] - p1[0]


        return yDiff / xDiff

    },

    calculatePointBetwwenTwoPointsAtaDistance: function (lon1, lat1, lon2, lat2, distance) {

        let totalDistance = this.calculateDistanceBetwwenPoints(lon1, lat1, lon2, lat2)
        let distanceRatio = distance / totalDistance
        let point = [(1 - distanceRatio) * lon1 + distanceRatio * lon2, (1 - distanceRatio) * lat1 + distanceRatio * lat2]
        return point
    },

    calculateDistanceBetwwenPoints: function (lon1, lat1, lon2, lat2) {

        function toRad(Value) {
            return Value * Math.PI / 180;
        }

        var R = 6371; // km
        var dLat = toRad(lat2 - lat1);
        var dLon = toRad(lon2 - lon1);
        var lat1 = toRad(lat1);
        var lat2 = toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d * 1000;
    },

    hexToRgb: function (hex) {

        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    },

    componentToHex: function (c) {

        let hex = c.toString(16);

        return hex.length == 1 ? "0" + hex : hex;
    },

    rgbToHex: function (r, g, b) {

        return "#" + utils.componentToHex(r) + utils.componentToHex(g) + utils.componentToHex(b);
    },

    getMidpoint: function (p1, p2) {

        return [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2]

    },

    getMidPointWithHeight: function (p1, p2, p1Height, p2Height) {

        return [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2, (p1Height + p2Height) / 2]
    },

    multiplyMatrix: function (m1, m2) {
        let result = [];

        for (let i = 0; i < m1.length; i++) {

            result[i] = [];

            for (let j = 0; j < m2[0].length; j++) {
                let sum = 0;

                for (let k = 0; k < m1[0].length; k++) {
                    sum += m1[i][k] * m2[k][j];
                }

                result[i][j] = sum;

            }
        }

        return result;
    },

    getUniqueId: function () {

        return String(uuidv4())
    },

    removeContainer: (params = []) => {

        params.forEach((param) => {

            let items = param.parent.querySelectorAll(param.key)

            for (const itm of items) {
                param.parent.removeChild(itm)
            }
        })
    },
    getTextImg: function (text, fontSize, cheight, cwidth, bgColor, textColor, x, y) {
        let img = document.createElement("img");
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext('2d');
        canvas.width = cwidth;
        canvas.height = cheight;
        /// lets save current state as we make a lot of changes        
        ctx.save();

        /// set font
        ctx.font = fontSize;

        /// draw text from top - makes life easier at the moment
        ctx.textBaseline = 'top';

        /// color for background
        ctx.fillStyle = bgColor;

        /// get width of text
        let width = ctx.measureText(text).width;
        //let height = ctx.measureText(text).height;

        /// draw background rect assuming height of fontSize
        ctx.fillRect(x, y, width, parseInt(fontSize, 10));

        /// text color
        ctx.fillStyle = textColor;

        /// draw text on top
        ctx.fillText(text, x, y);

        /// restore original state
        ctx.restore();
        img.src = canvas.toDataURL();
        return img
    },

    getRatioBetweenNumbers(num_1, num_2) {
        for (let num = num_2; num > 1; num--) {
            if ((num_1 % num) == 0 && (num_2 % num) == 0) {
                num_1 = num_1 / num;
                num_2 = num_2 / num;
            }
        }
        ratio = [num_1, num_2];
        //ratio = num_1+":"+num_2;

        return ratio;
    },


    findAngleBetweenThreePoints(A, B, C) {
        let AB = Math.sqrt(Math.pow(B[0] - A[0], 2) + Math.pow(B[1] - A[1], 2));
        let BC = Math.sqrt(Math.pow(B[0] - C[0], 2) + Math.pow(B[1] - C[1], 2));
        let AC = Math.sqrt(Math.pow(C[0] - A[0], 2) + Math.pow(C[1] - A[1], 2));
        return Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB)) * 57.2958;
    },

    radiansToDegrees(radians) {
        var pi = window.Math.PI;
        return radians * (180 / pi);
    },

    findPointSide(linePA, linePB, point) {

        return ((linePB[0] - linePA[0]) * (point[1] - linePA[1]) - (linePB[1] - linePA[1]) * (point[0] - linePA[0])) > 0;
    },

    findDistanceBetweenPointAndLine(point, linePA, linePB) {

        let slope = (linePB[1] - linePA[1]) / (linePB[0] - linePA[0])
        let intercept = linePB[1] - slope * linePB[0]

        let a = 1; let b = -slope; let c = -intercept

        let distance = window.Math.abs(a * point[0] + b * point[1] + c) / window.Math.sqrt(a * a + b * b)
        return distance

    },

    getPolygonBoundary(points) {

        let latMin, latMax, lonMin, lonMax

        for (let i = 0; i < points.length; i += 2) {

            let lon = points[i]
            let lat = points[i + 1]

            if (!latMin || lat < latMin) latMin = lat;
            if (!latMax || lat > latMax) latMax = lat;
            if (!lonMax || lon > lonMax) lonMax = lon;
            if (!lonMin || lon < lonMin) lonMin = lon;

        }

        return [lonMin, latMax, lonMax, latMax, lonMax, latMin, lonMin, latMin, lonMin, latMax]


    },

    getRectangeleCenter(points) {

        let [lonMin, latMax, _, __, lonMax, latMin, ____, ___, ..._____] = points;

        let center = utils.getMidpoint([lonMin, latMax], [lonMax, latMin])

        return center

    },

    getRandomColor() {
        var letters = '0123456789ABCDEF',
            color = '#';

        for (var i = 0; i < 6; i++) color += letters[Math.floor(Math.random() * 16)];

        return color;
    },


    getClickableItem(items) {

        if (!items.length) return

        for (let i = 0; i < items.length; i++) {

            let item = items[i];

            if (item.id.name == 'viewShedRegionWithOutline') continue

            return item

        }



    },

    minToTimeInDays: (minutes) => {
        const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        let date = new Date()
        date.setDate(date.getDate() + (parseInt(minutes / 60 / 24)));
        return `${parseInt(minutes / 60 / 24) ? (date.getDate()) + "-" + months[date.getMonth()] + "-" + date.getFullYear() + " " : ""}${("0" + (parseInt(minutes / 60) - parseInt(minutes / 60 / 24) * 24)).slice(-2)}:${("0" + parseInt(minutes - (parseInt(minutes / 60) * 60))).slice(-2)}`;
    },
    startDateAndMinToTimeInDays: (startDate, minutes) => {
        const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        let date = new Date(startDate)
        date.setDate(date.getDate() + (parseInt(minutes / 60 / 24)));
        return `${parseInt(minutes / 60 / 24) ? (date.getDate()) + "-" + months[date.getMonth()] + "-" + date.getFullYear() + " " : ""}${("0" + (parseInt(minutes / 60) - parseInt(minutes / 60 / 24) * 24)).slice(-2)}:${("0" + parseInt(minutes - (parseInt(minutes / 60) * 60))).slice(-2)}`;
    },
    minToHrs: (minutes) => {
        return `${("0" + parseInt(minutes / 60)).slice(-2)}:${("0" + parseInt(minutes - (parseInt(minutes / 60) * 60))).slice(-2)}`
    },
    minToHrsWithLabel: (minutes) => {
        // console.log(minutes,(minutes / 60) - parseInt(minutes / 60 / 24))
        return (
            (parseInt(minutes / 60 / 24) ? (`${parseInt(minutes / 60 / 24)} ` + (((parseInt(minutes / 60 / 24) > 1) ? '<span style="color:#707174">D</span>' : '<span style="color:#707174">D</span>') + ` `)) : '') +
            (((minutes / 60 % 24) >= 1) ? (parseInt(minutes - (parseInt(minutes / 60) * 60)) ? (`${("0" + parseInt((minutes / 60) - parseInt(minutes / 60 / 24) * 24)).slice(-2)} ` + ((parseInt(minutes / 60 % 24) > 1) ? '<span style="color:#707174">Hr</span>' : '<span style="color:#707174">Hr</span>') + ` : `) : (`${("0" + parseInt((minutes / 60) - parseInt(minutes / 60 / 24) * 24)).slice(-2)} ` + ((parseInt(minutes / 60 % 24) > 1) ? '<span style="color:#707174">Hr</span>' : '<span style="color:#707174">Hr</span>'))) : '') +
            (parseInt(minutes - (parseInt(minutes / 60) * 60)) ? `${("0" + parseInt(minutes - (parseInt(minutes / 60) * 60))).slice(-2)} ` + ((parseInt(minutes - (parseInt(minutes / 60) * 60)) > 1) ? '<span style="color:#707174">min</span>' : '<span style="color:#707174">min</span>') : ''));
    },
    minToHrsWithHrsMinLabel: (minutes) => {
        return ((parseInt(minutes / 60) ? (parseInt(minutes - (parseInt(minutes / 60) * 60)) ? (`${("0" + parseInt(minutes / 60)).slice(-2)} ` + ((parseInt(minutes / 60) > 1) ? '<span style="color:#707174">hours</span>' : '<span style="color:#707174">hour</span>') + ` : `) : (`${("0" + parseInt(minutes / 60)).slice(-2)} ` + ((parseInt(minutes / 60) > 1) ? '<span style="color:#707174">hours</span>' : '<span style="color:#707174">hour</span>'))) : '') + (parseInt(minutes - (parseInt(minutes / 60) * 60)) ? `${("0" + parseInt(minutes - (parseInt(minutes / 60) * 60))).slice(-2)} ` + ((parseInt(minutes - (parseInt(minutes / 60) * 60)) > 1) ? '<span style="color:#707174">minutes</span>' : '<span style="color:#707174">minute</span>') : ''));
    },
    ReverseGeo: async (lat, lng, globalStore) => {

        let details = await globalStore.reverseGeoCode.getPlaceDetails({ location: [lat, lng] })
        let Address = ""

        if (details != undefined) {
            let { name, country, state, city } = details.properties;
            let address = (country || '')

            address += state ? ', ' + state : ''
            Address = name + ',' + city + ',' + state + ',' + country
            return Address;

            //   document.getElementById(`${clickedbtn}latlng-${activetab}`).value = name + ',' + city + ',' + state + ',' + country
        } else {
            //   document.getElementById(`${clickedbtn}latlng-${activetab}`).value = `${lat},${lng}`
            return `${lat} , ${lng}`;

        }
    },
    getISTfromIUTC(time) {
        let ms = new Date(time).getTime()
        return ms + (5.5 * 60 * 60 * 1000)
    },
    getLocalDate: (ms) => {

        let date = new Date(ms)
        let t = date.toString().split(" ");

        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;

        let str = `${strTime} | ${t[2]} ${t[1]} ${t[3]}`

        return str;
    },
    split_getLocalDate: (ms) => {

        ms = ms ? ms : new Date().getTime();

        let date_obj = new Date(ms)
        let date_str = JSON.stringify(date_obj).split('"')[1].split('T')[0]

        let hours = date_obj.getHours();
        let minutes = date_obj.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let time_str = hours + ':' + minutes + ' ' + ampm;

        return [time_str, date_str]
    },
    getLocalFullDate(ms) {

        if (!ms) ms = new Date().getTime()

        let data = new Date(ms)
        const yyyy = data.getFullYear();
        let mm = data.getMonth() + 1;
        let dd = data.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const D = dd + '/' + mm + '/' + yyyy;

        return D;
    },
    NEW_getLocalFullDate(ms) {

        if (!ms) return '-'

        let data = new Date(ms)
        const yyyy = data.getFullYear();
        let mm = data.getMonth() + 1;
        let dd = data.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const D = dd + '/' + mm + '/' + yyyy;

        return D;
    },

    getTimeRemaning(time) {

        let D = 0, H = 0, M = 0, Mn = 0;
        let timediff = (new Date(time).getTime() - new Date().getTime())

        timediff = Math.floor(timediff / 1000)

        if (timediff < 3600) //less than an hour
        {

            M = Math.floor(timediff / 60);
        }
        else if (timediff < 86400) //less than a day
        {
            H = Math.floor(timediff / 3600);
            M = Math.floor((timediff % 3600) / 60);
        }
        else if (timediff < 2592000) //less than a month
        {
            D = Math.floor(timediff / 86400);
            H = Math.floor((timediff % 86400) / 3600);
            M = Math.floor((timediff % 3600) / 60);
        }
        else if (timediff < 3.154e+7) //less than a years
        {
            Mn = Math.floor(timediff / 2.628e+6);
            D = Math.floor((timediff % 2.628e+6) / 86400);
            H = Math.floor((timediff % 86400) / 3600);
            M = Math.floor((timediff % 3600) / 60);
        }

        if (timediff < 0)
            return "Expired";
        else if (Mn > 0)
            return `${Mn}M ${D}d ${H}h ${M}m`
        else if (D > 0)
            return `${D}d ${H}h ${M}m`
        else if (H > 0)
            return `${H}h ${M}m`
        else
            return `${M}m`

    },
    getLocalFullDate_reverse(ms) {

        if (!ms) ms = new Date().getTime()

        let data = new Date(ms)
        let yyyy = data.getFullYear();
        let mm = data.getMonth() + 1;
        let dd = data.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const D = [yyyy, mm, dd].join('-')

        return D;
    },
    _getLocalFullDate(ms) {

        if (!ms) ms = new Date().getTime()

        let data = new Date(ms)
        let yyyy = data.getFullYear();
        let mm = data.getMonth() + 1;
        let dd = data.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;


        let hours = data.getHours();
        let minutes = data.getMinutes();

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        const D = [yyyy, mm, dd].join('-') + "T" + `${hours}:${minutes}`

        return D;
    },
    getDayfromValue(value) {

        let data = new Date()
        let yyyy = data.getFullYear();
        let mm = data.getMonth() + 1;
        let dd = data.getDate() + value;

        new Date(yyyy, mm, dd)

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return new Date(yyyy, mm, dd).getTime()
    },
    getEasyDate(duration) {

        let [start, end] = [undefined, undefined]

        // "2022-11-06T10:15"

        if (duration == 'tomorrow') {

            let date_Obj = new Date()
            let yyyy = date_Obj.getFullYear();
            let mm = date_Obj.getMonth() + 1;
            let dd = date_Obj.getDate() + 1;

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            start = ([yyyy, mm, dd].join('-')) + 'T00:00'
            end = ([yyyy, mm, dd].join('-')) + 'T23:59'
        }
        else if (duration == 'today') {

            let date_Obj = new Date()
            let yyyy = date_Obj.getFullYear();
            let mm = date_Obj.getMonth() + 1;
            let dd = date_Obj.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            start = ([yyyy, mm, dd].join('-')) + 'T00:00'
            end = ([yyyy, mm, dd].join('-')) + 'T23:59'
        }
        else if (duration == 'yesterday') {

            let date_Obj = new Date()
            let yyyy = date_Obj.getFullYear();
            let mm = date_Obj.getMonth() + 1;
            let dd = date_Obj.getDate() - 1;

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            start = ([yyyy, mm, dd].join('-')) + 'T00:00'
            end = ([yyyy, mm, dd].join('-')) + 'T23:59'
        }
        else if (duration == 'week') {

            let date_Obj = new Date
            let first = date_Obj.getDate() - date_Obj.getDay();
            let last = first + 6;

            let firstday = new Date(date_Obj.setDate(first))
            let lastday = new Date(date_Obj.setDate(last))


            {
                let yyyy = firstday.getFullYear();
                let mm = firstday.getMonth() + 1;
                let dd = firstday.getDate() - 1;

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                start = ([yyyy, mm, dd].join('-')) + 'T00:00'

            }
            {
                let yyyy = lastday.getFullYear();
                let mm = lastday.getMonth() + 1;
                let dd = lastday.getDate() - 1;

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                end = ([yyyy, mm, dd].join('-')) + 'T23:59'

            }
        }

        return { start, end }
    },
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    synchronousLoop(data, processData, done) {

        let isBreaked = false

        let breakloop = () => {
            isBreaked = true
            console.log('isBreaked', isBreaked);
            return isBreaked;
        }

        if (data.length > 0) {

            let loop = (data, i, processData, done) => {

                if (isBreaked) return done();

                processData(data[i], i, () => {

                    if (++i < data.length) setTimeout(() => loop(data, i, processData, done), 0)
                    else done();
                })

            }

            loop(data, 0, processData, done)

        } else done()

        return breakloop;

    },
    getCustomFullDate(formate, type) {

        let date = new Date(),
            current_year = date.getFullYear(),
            current_day = date.getDate(),
            current_month = date.getMonth();

        let stack = []

        if (formate == 'day') {

            for (let i = 0; i < current_day; i++) {

                let start = new Date(current_year, current_month, i)
                let end = new Date(current_year, current_month, i)

                start.setHours(0, 0, 0, 0);
                end.setHours(23, 59, 59, 999);

                if (type == 'office-ride') {
                    stack.push({ index: i, start, end })
                }
                else {
                    stack.push({ index: i, start: JSON.stringify(start), end: JSON.stringify(end) })
                }
            }
        }
        else if (formate == 'month') {


            for (let i = 0; i <= current_month; i++) {

                let start = new Date(current_year, i, 1)
                let end = new Date(current_year, i, 31)

                end = i == current_month ? new Date() : end;

                start.setHours(0, 0, 0, 0);
                end.setHours(23, 59, 59, 999);

                if (type == 'office-ride') {
                    stack.push({ index: i, start, end })
                }
                else {
                    stack.push({ index: i, start: JSON.stringify(start), end: JSON.stringify(end) })
                }
            }
        }
        else if (formate == 'week') {

            const weeks = [],
                firstDate = new Date(current_year, current_month, 1),
                lastDate = new Date(current_year, current_month + 1, 0),
                numDays = lastDate.getDate();

            let dayOfWeekCounter = firstDate.getDay();

            for (let date = 1; date <= numDays; date++) {

                if (date > current_day) break;

                if (dayOfWeekCounter === 0 || weeks.length === 0) {
                    weeks.push([]);
                }
                weeks[weeks.length - 1].push(date);
                dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
            }

            weeks
                .filter((w) => !!w.length)
                .map((w, i) => {


                    let start = new Date(current_year, current_month, (w[0] - 1))
                    let end = new Date(current_year, current_month, (w[w.length - 1] - 1))

                    start.setHours(0, 0, 0, 0);
                    end.setHours(23, 59, 59, 999);

                    if (type == 'office-ride') {
                        stack.push({ index: i, start, end })
                    }
                    else {
                        stack.push({ index: i, start: JSON.stringify(start), end: JSON.stringify(end) })
                    }
                });
        }

        return stack
    },
    getCurrent_Day(type) {

        JSON.stringify
        let start = new Date();
        start.setHours(0, 0, 0, 0);
        let end = new Date();
        end.setHours(23, 59, 59, 999);

        if (type == 'office-ride') {
            return { start: start, end: end }
        }
        else {
            return { start: JSON.stringify(start), end: JSON.stringify(end) }
        }
    },
    getCurrent_Week(type) {

        let curr = new Date();
        let first = curr.getDate() - curr.getDay();
        let last = first + 6;
        let start = new Date(curr.setDate(first))
        let end = new Date(curr.setDate(last))

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);

        if (type == 'office-ride') {
            return { start: start, end: end }
        }
        else {
            return { start: JSON.stringify(start), end: JSON.stringify(end) }
        }
    },
    getCurrent_Month(type) {

        let date = new Date(), y = date.getFullYear(), m = date.getMonth();
        let start = new Date(y, m, 1)
        let end = new Date(y, m + 1, 0)

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);


        if (type == 'office-ride') {
            return { start: start, end: end }
        }
        else {
            return { start: JSON.stringify(start), end: JSON.stringify(end) }
        }
    },
    getCurrent_year(type) {

        let currentYear = new Date().getFullYear();
        let start = new Date(currentYear, 0, 1);
        let end = new Date(currentYear, 11, 31);

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);


        if (type == 'office-ride') {
            return { start: start, end: end }
        }
        else {
            return { start: JSON.stringify(start), end: JSON.stringify(end) }
        }
    },

    getTableCols(table) {

        var th = table.querySelectorAll('thead tr:first-child th');

        return [].reduce.call(th, function (p, c) {
            var colspan = c.getAttribute('colspan') || 1;
            return p + +colspan;
        }, 0);
    },

    BasicReverseGeo: async (lat, lng, globalStore) => {

        let details = null;
        try {
            details = await globalStore.reverseGeoCode.getPlaceDetails({ location: [lat, lng] })
        } catch {
            details = undefined;
        }
        let Address = ""

        if (details != undefined) {
            let { name, country, state, city, postcode } = details.properties;
            let address = (country || '')
            let tempName = (name != undefined) ? `${name}, ` : ``
            let tempCity = (city != undefined) ? `${city}, ` : ``
            let tempCountry = (country != undefined) ? `${country}, ` : ``
            let tempState = (state != undefined) ? `${state}, ` : ``

            address += state ? ', ' + state : ''
            Address = tempName + tempCity + tempState + tempCountry + postcode
            return Address;

            //   document.getElementById(`${clickedbtn}latlng-${activetab}`).value = name + ',' + city + ',' + state + ',' + country
        } else {
            //   document.getElementById(`${clickedbtn}latlng-${activetab}`).value = `${lat},${lng}`
            return `${lat} , ${lng}`;

        }
    },
    paginator: (instance, params) => {

        console.log(instance)
        console.log(params)
        if (instance.current === undefined) {
            let param = new URLSearchParams(window.location.search);
            instance.current = param.has("pg") ? Number.parseInt(param.get("pg")) : 1;
        }
        if (instance.adj === undefined) { instance.adj = 2; }
        if (instance.adj <= 0) { instance.adj = 1; }
        if (instance.current <= 0) { instance.current = 1; }
        if (instance.current > instance.total) { instance.current = instance.total; }

        // (B) URL STRING ONLY - DEAL WITH QUERY STRING & APPEND PG=N
        const jsmode = typeof instance.click == "function";
        if (jsmode == false) {
            if (instance.click.indexOf("?") == -1) { instance.click += "?pg="; }
            else { instance.click += "&pg="; }
        }

        // (C) HTML PAGINATION WRAPPER
        instance.target.innerHTML = "Pages: &nbsp;";
        instance.target.classList.add("paginate");

        // (D) DRAW PAGINATION SQUARES
        // (D1) HELPER FUNCTION TO DRAW PAGINATION SQUARE
        const square = (txt, pg, css) => {
            let el = document.createElement("a");
            el.innerHTML = txt;
            if (css) { el.className = css; }
            if (jsmode) { el.onclick = () => { instance.click(pg, params); }; }
            else { el.href = instance.click + pg; }
            instance.target.appendChild(el);
            if (pg == 0) {
                instance.target.innerHTML = "";
            }
        };
        // (D2) BACK TO FIRST PAGE (DRAW ONLY IF SUFFICIENT SQUARES)
        if (instance.current - instance.adj > 1) { square("&#10218;", 1, "first"); }

        // (D3) ADJACENT SQUARES BEFORE CURRENT PAGE
        let temp;
        if (instance.current > 1) {
            temp = instance.current - instance.adj;
            if (temp <= 0) { temp = 1; }
            for (let i = temp; i < instance.current; i++) { square(i, i); }
        }

        // (D4) CURRENT PAGE
        square(instance.current, instance.current, "current");

        // (D5) ADJACENT SQUARES AFTER CURRENT PAGE
        if (instance.current < instance.total) {
            temp = instance.current + instance.adj;
            if (temp > instance.total) { temp = instance.total; }
            for (let i = instance.current + 1; i <= temp; i++) { square(i, i); }
        }

        // (D6) SKIP TO LAST PAGE (DRAW ONLY IF SUFFICIENT SQUARES)
        if (instance.current <= instance.total - instance.adj - 1) {
            square("&#10219;", instance.total, "last");
        }
    }



}

module.exports = utils