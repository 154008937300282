
import Images from '../../../assets/images'
import Utilts from '../../utils'

class EnquiryUiController {

    constructor({ globalStore, parentContainer }) {
        this.globalStore = globalStore
        this.parentContainer = parentContainer
    }

    async submitEnquiryForm(data){

        let base_url = "https://vmmaps.com/officeride/api"
        // let base_url = "http://localhost:1997/api"

        let response = await fetch(base_url + '/upload-not-customer-leads', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept':'application/json'
            },
            body: JSON.stringify({id: data.id, data})

        })

        response = await response.json()

        if (response.success)  return response.message
        else return response.message
        

    }

    makeContainer() {
        let container = document.createElement('div')
        container.classList.add('project-enquiry-popup-ui-block')
        container.innerHTML = `

            <div class='blockui-mask'></div>
            <div class='enquiry-popup-container-main'>
                <div class='enquiry-popup-container-header'>

                    <div class='enquiry-header-label'>Contact Us</div>
                    <div class='enquiry-header-close-btn' id='enquiry-popup-container-close'>
                        <i class='bx bx-x'></i>
                    </div>
                </div>
                <form class="enquiry-popup-content" id='enquiry-popup-content-form'>
                    <div class="enquiry-content-item">
                        <label for='enquiry-username' >User Name</label>
                        <input type='text' required id='enquiry-username' placeholder='Enter your name'/>
                    </div>
                    <div class="enquiry-content-item">
                        <label for='enquiry-phone' >Phone number</label>
                        <input type='text' required id='enquiry-phone' placeholder='Enter your phone number'/>
                    </div>
                    <div class="enquiry-content-item">
                        <label for='enquiry-email' >Email address</label>
                        <input type='text' required id='enquiry-email' placeholder='Enter your email address'/>
                    </div>
                    <div class="enquiry-content-item">
                        <label for='enquiry-location' >Location</label>
                        <input type='text' required id='enquiry-location' placeholder='Enter your location'/>
                        <div class='enquiry-location-res' id='enquiry-location-res'></div>
                    </div>
                    <div class="enquiry-content-item">
                        <label for='enquiry-message' >Message</label>
                        <textarea id='enquiry-message' required placeholder='Leave your location'></textarea>
                    </div>
                    <div class="enquiry-content-submit-item">
                        <button type='submit' class='enquiry-content-submit-btn' id='enquiry-content-submit-btn'>Submit</button>
                    </div>
                </form>
            </div>
        `

        return container
    }
    hide(){
        if(this.parentContainer.querySelector('.project-enquiry-popup-ui-block')) this.parentContainer.removeChild(this.container)
    }
    addEvent() {
        let container_main = this.container.querySelector('.enquiry-popup-container-main')
        let close_btn = this.container.querySelector('#enquiry-popup-container-close')
        
        let username = this.container.querySelector('#enquiry-username')
        let phone = this.container.querySelector('#enquiry-phone')
        let email = this.container.querySelector('#enquiry-email')
        let location = this.container.querySelector('#enquiry-location')
        let loc_search_res = this.container.querySelector('#enquiry-location-res')

        let message = this.container.querySelector('#enquiry-message')

        let submit_btn = this.container.querySelector('#enquiry-content-submit-btn')
        let submit_form = this.container.querySelector('#enquiry-popup-content-form')


        {
         
            let location_callback = async (value, inputfield, parent, id) => {

                let disableResContainer = () => {
                    parent.style.display = 'none'
                }
                let enableResContainer = () => {
                    parent.style.display = 'flex'
                }
    
                enableResContainer()
    
                let makeResItem = (itm) => {
    
                    let name = itm.properties.name;
                    let country = itm.properties.country;
                    let city = itm.properties.city;
                    let state = itm.properties.state;
    
                    let value = ''
                    value += name ? name + ', ' : ''
                    value += city ? city + ', ' : ''
                    value += state ? state + ', ' : ''
    
                    value = value.replace(/^,|,$/g, '');
    
                    let container = document.createElement('span')
                    container.innerHTML = `${value}`
                    return container
                }
                let eventResItem = (container, itm) => {
                    container.addEventListener('click', (e) => {
    
                        let name = itm.properties.name;
                        let country = itm.properties.country;
                        let city = itm.properties.city;
                        let state = itm.properties.state;
    
                        let value = ''
                        value += name ? name + ', ' : ''
                        value += city ? city + ', ' : ''
                        value += state ? state + ', ' : ''
                        value += country ? country + ', ' : ''
    
                        value = value.replace(/^,|,$/g, '');
    
                        inputfield.value = value
    
                        disableResContainer()
                    })
                }
    
                if (this.abortController) this.abortController.abort()
    
                this.abortController = new AbortController();
                this.abortSignal = this.abortController.signal;
    
                try {
                    let response = await this.globalStore.search.search({ text: value, signal: this.abortSignal })
    
                    if (response && Array.isArray(response)) {
    
                        enableResContainer()
    
                        parent.innerHTML = ``
    
                        response.forEach((itm) => {
    
                            let container = makeResItem(itm)
    
                            parent.appendChild(container)
                            eventResItem(container, itm)
    
                        })
                    }
                } catch (e) {
                    console.log(e);
                }
    
                addEventListener('click', (e) => {
    
                    let path = e.path || (e.composedPath && e.composedPath());
    
                    if (Array.isArray(path) && !path.includes(parent) && !path.includes(inputfield)) disableResContainer()
    
                })
    
                }


                let loc_inputs_timer = undefined
                let delay = 200
                location.addEventListener('input', (e) => {
    
                    if (loc_inputs_timer) clearTimeout(loc_inputs_timer)
                    
                    loc_inputs_timer = setTimeout(() => {
                    
                        let value = e.target.value
                    
                        location_callback(value, location, loc_search_res, location.id)
                    }, delay)
                })
        }

        close_btn.addEventListener('click', (e)=>{
            this.hide()
        })
        
        submit_form.addEventListener('submit', async (e)=>{

            e.preventDefault()

            let data = {
                id: Utilts.getUniqueId(),
                created_at: new Date().getTime(),
                lastupdated: new Date().getTime(),
                username: username.value,
                phone: phone.value,
                email: email.value,
                location: location.value,
                message: message.value,
            }

            
            submit_btn.innerHTML = `
            
                <div class="enquiry-content-submit-loading">
                  <div class="enquiry-content-submit-loading-bar"></div>
                </div>
                Loading...
            `

            let response = await this.submitEnquiryForm(data)

            if(response){
                
                console.log(response, 'response');
                
                container_main.innerHTML =`
                    <div class="enquiry-thankyou-container">
                      <div class="enquiry-thankyou-container-image">
                        <img src='${Images.contact_us_svg}'/>
                      </div>
                      <div class="enquiry-thankyou-container-content">
                        <div class="enquiry-thankyou-container-content-title">
                            Thanks for Contacting Us
                        </div>
                        <div class="enquiry-thankyou-container-content-desc">
                            Our Support tem will reach you out and solve your query.
                        </div>
                        <div class="enquiry-thankyou-container-content-close">
                            Close
                        </div>
                      </div>
                    </div>
                `

                let close_btn = container_main.querySelector('.enquiry-thankyou-container-content-close')
                close_btn.addEventListener('click', (e)=>{
                    this.hide()
                })

            }

        })
    }
    show() {

        this.container = this.makeContainer()
        this.parentContainer.appendChild(this.container)
        this.addEvent()
    }


}

export default EnquiryUiController;