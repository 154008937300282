import Images from '../../../assets/Images'
class LandingUiConoller {

    constructor({ globalStore, parentContainer }) {

        this.globalStore = globalStore
        this.parentContainer = parentContainer

    }

    makeContainer() {
        let container = document.createElement('div')
        container.classList.add('project-landing-main')
        container.innerHTML = `
            <div class='landing-items'>
                <h2 class='landing-item landing-smalltitle'>
                    Namma Ooru Taxi !
                </h2>
                <h1 class='landing-item landing-title'>
                    The <span>One-Stop Solution</span> for all your 
                    Transportation Needs!
                </h1>
                <p class='landing-item landing-desc'>
                    Book a taxi in just a few minutes and get picked up from your location by the car of your choice. There's no need to wait and let the driver find you!
                </p>
               
            </div>               
        `

        return container
    }

    addEvent() { }
    show() {

        this.container = this.makeContainer()
        this.parentContainer.appendChild(this.container)
        this.addEvent()
    }


}

export default LandingUiConoller;