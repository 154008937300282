import Images from '../../../assets/Images'
import Icon from '../../../assets/Icon'
import Utils from '../../utils'

class TippackagesUiController {

    constructor({ globalStore, parentContainer }) {

        this.globalStore = globalStore
        this.parentContainer = parentContainer

    }

    makePackageItem(item) {
        let container = document.createElement('div')
        container.classList.add('tippackages-section')
        container.innerHTML = `
            <div class='tippackages-section-img'>
                <img src='${item.fromImage}' title='From'/>
                <img src='${item.toImage}' title='To'/>
            </div>    
            <div class='tippackages-section-flotting'>
                <div class='section-flotting-from'>
                    ${item.fromName}
                    <span>, ${item.fromSubName}</span>
                </div> 
                <div class='section-flotting-icon'>
                ${Icon.swap}
                </div> 
                <div class='section-flotting-to'>
                    ${item.toName}
                    <span>, ${item.toSubName}</span>
                </div> 
            </div>    
            <div class='tippackages-section-details'>
                <div class='section-details-left'>
                    <p>Starts From</p>
                    <span>₹  ${item.price}/-</span>
                </div> 
                <div class='section-details-right'>
                    BOOK NOW
                </div> 
            </div>    
        `
        return container
    }

    renderPackagesItems() {
        let Items = [

            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },

            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
            {
                fromImage: Images.locationfrom,
                toImage: Images.locationto,
                fromName: "Chennai",
                fromSubName: "Tamil Nadu",
                toName: "Pondicherry",
                toSubName: "India",
                price: "10,000",
                url: "",
            },
        ]

        let itemsParent = this.container.querySelector('.tippackages-sections')

        itemsParent.innerHTML = ``

        Items.forEach((itm, i) => {

            let container = this.makePackageItem(itm)
            itemsParent.appendChild(container)
        })
    }


    makeContainer() {
        let container = document.createElement('div')
        container.classList.add('project-tippackages-main')
        container.innerHTML = `
            <div class='tippackages-sections-bg'></div>
            <div class='tippackages-items'>
                <div class='tippackages-title-main'>
                    ${Icon.borderleft}
                    <div class='tippackages-title'>
                        Popular <span>Trip package</span>
                    </div>
                    ${Icon.borderright}
                </div>               
                <div class='tippackages-desc'>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo jhbum.</p>
                </div>               
                <div class='tippackages-sections' id='tippackages-sections'>

                </div>             
            </div>               
        `

        return container
    }



    addEvent() {
        this.renderPackagesItems()

        Utils.drag_ScrollVertical('tippackages-sections')
    }
    show() {

        this.container = this.makeContainer()
        this.parentContainer.appendChild(this.container)
        this.addEvent()
    }


}

export default TippackagesUiController;