export default {



      'searchApiUrl': `https://nav.vmmaps.com/api`,
      'searchAccessToken': 'VMADMIN',

      'reverseApiUrl': `https://nav.vmmaps.com/reverse`,

      'reverseAccessToken': 'VMADMIN',

}
