import Images from '../../../assets/Images'
import Icon from '../../../assets/Icon'
class FleetofcarsUiController {

    constructor({ globalStore, parentContainer }) {

        this.globalStore = globalStore
        this.parentContainer = parentContainer

        this.leftmenu_opened_itm = undefined

        this.Items = [

            {
                id: 'fleetofcars-right-image1',
                title: 'Motor Bike',
                desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo jhbum.',
            },
            {
                id: 'fleetofcars-right-image2',
                title: 'Auto Rickshaw',
                desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo jhbum.',
            },
            {
                id: 'fleetofcars-right-image3',
                title: 'Hatchback',
                desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo jhbum.',
            },
            {
                id: 'fleetofcars-right-image4',
                title: 'Sedan',
                desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo jhbum.',
            },
            {
                id: 'fleetofcars-right-image5',
                title: 'SUV',
                desc: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo jhbum.',
            },

        ]

    }

    smoothScroll() {

        Math.easeInOutQuad = function (t, b, c, d) {

            //t = current time
            //b = start value
            //c = change in value
            //d = duration

            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        };

        function scrollTo(element, to, duration) {
            var start = element.scrollTop,
                change = to - start,
                currentTime = 0,
                increment = 10;

            var animateScroll = function () {
                currentTime += increment;
                var val = Math.easeInOutQuad(currentTime, start, change, duration);
                element.scrollTo(0, val);

                if (currentTime < duration) {
                    setTimeout(animateScroll, increment);
                }
            };
            animateScroll();
        }


        return scrollTo
    }


    makeFleetofcarsItem(item) {
        let { id, title, desc } = item
        let container = document.createElement('div')
        container.classList.add('fleetofcars-sections-left-itm')
        container.id = id
        container.innerHTML = `
            <div class='fleetofcars-left-title'>${title}</div>
            <div class='fleetofcars-left-desc'>${desc}</div>
        `

        return container
    }

    eventFleetofcarsItem(item, index, container) {
        let title = container.querySelector('.fleetofcars-left-title')
        let desc = container.querySelector('.fleetofcars-left-desc')

        let slideOpen = true;
        let heightChecked = false;
        let initHeight = 0;
        let initOpacity = 0;
        let intval = null;


        let sliderOpenEvent = (desc) => {

            window.clearInterval(intval);

            if (!heightChecked) {
                initHeight = desc.offsetHeight;
                heightChecked = true;
            }

            if (slideOpen) {

                let h = initHeight;
                let o = initOpacity;

                desc.style.opacity = 0;
                container.style.borderColor = '#2c3041'
                title.style.color = 'var(--text-light-color)'

                slideOpen = false;
                intval = setInterval(function () {
                    h--;
                    o -= o - 0.1;
                    desc.style.height = h + 'px';

                    if (h <= 0) {
                        window.clearInterval(intval);
                    }
                }, 1);

                return true;
            }
            else {

                let h = 0;
                let o = 0;
                slideOpen = true;
                title.style.color = 'var(--primary-color)'

                intval = setInterval(function () {
                    h++;
                    o += o + 0.1;

                    desc.style.height = h + 'px';
                    desc.style.opacity = o;

                    if (h >= initHeight) {
                        desc.style.opacity = o;
                        container.style.borderColor = 'var(--primary-color)'

                        window.clearInterval(intval);
                    }
                }, 1);

                return false;
            }

        }

        let titleClickHandler = (init = false) => {

            if (!init) this.rightImageEvent(item)


            if (this.leftmenu_opened_itm && this.leftmenu_opened_itm != titleClickHandler) {
                this.leftmenu_opened_itm(true)
                this.leftmenu_opened_itm = undefined
            }

            let res = sliderOpenEvent(desc)
            if (res) this.leftmenu_opened_itm = undefined
            else this.leftmenu_opened_itm = titleClickHandler
        }

        title.addEventListener('click', (e) => {

            titleClickHandler()
        })

        titleClickHandler(index == 0 ? false : true)
    }
    rightImageEvent(item) {

        let id = item.id

        let parent = this.container.querySelector('.fleetofcars-section-right-images');
        let image = this.container.querySelector(`#${id}`);

        for (const itm of parent.children) itm.classList.remove('fleetofcars-item-active')

        let index = (id) => {
            let items = id.split('-')

            items = items[items.length - 1]
            let index = parseInt(items[items.length - 1]) - 1

            index = index < 0 ? 0 : index;

            return index
        }

        console.log(index(id));

        parent.children[index(id)].classList.add('fleetofcars-item-active')
    }
    renderFleetofcarsItems() {


        let itemsParent = this.container.querySelector('.fleetofcars-section-left')

        itemsParent.innerHTML = ``

        this.Items.forEach((itm, i) => {

            let container = this.makeFleetofcarsItem(itm)
            itemsParent.appendChild(container)
            this.eventFleetofcarsItem(itm, i, container)
        })
    }
    makeContainer() {
        let container = document.createElement('div')
        container.classList.add('project-fleetofcars-main')
        container.innerHTML = `
            <div class='fleetofcars-items'>
                 <div class='fleetofcars-item'>
                     <div class='fleetofcars-title-main'>
                         ${Icon.borderleft}
                         <div class='fleetofcars-title'>
                             Fleet <span>of Cars</span>
                         </div>
                         ${Icon.borderright}
                     </div>               

                     <div class='fleetofcars-desc'>
                         Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo jhbum.
                     </div>          
                     <div class='fleetofcars-sections'>
                         <div class='fleetofcars-section-left'>
                         </div>
                     </div>             
                </div>
                <div class='fleetofcars-section-right'>
                    <div class='fleetofcars-section-right-images'>
                        <img id='fleetofcars-right-image1' src='${Images.bikeType}'/>     
                        <img id='fleetofcars-right-image2' src='${Images.autoType}'/>     
                        <img id='fleetofcars-right-image3' src='${Images.hatcback}'/>     
                        <img id='fleetofcars-right-image4' src='${Images.sedan}'/>     
                        <img id='fleetofcars-right-image5' src='${Images.suv}'/>     
                    </div>  
                </div>  
            </div>  
        `

        return container
    }
    addEvent() {
        this.renderFleetofcarsItems()
    }
    show() {

        this.container = this.makeContainer()
        this.parentContainer.appendChild(this.container)
        this.addEvent()
    }
}

export default FleetofcarsUiController;