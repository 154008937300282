import PopupUiController from './popupContainer/popupUiController'
import EnquiryUiController from './enquiryContainer/enquiryUiController'

import HeaderUiConoller from './header/headerUiController'
import LandingUiConoller from './landing/landingUiController'
import OccasssionTypesUiController from './occasssionTypes/occasssionTypesUiController'
import WhyNOTUiController from './whyNOT/whyNOTTypesUiController'
import FleetofcarsUiController from './fleetofcars/fleetofcarsUiController'
import TippackagesUiController from './tippackages/tippackagesUiController'
import AvailableLocationUiController from './availableLocation/availableLocationUiController'
import WorkprocessUiController from './workprocess/workprocessUiController'
import ApplicationUiController from './application/applicationUiController'
import TestimonialUiController from './testimonial/testimonialUiController'
import ForCorporatesUiController from './forCorporates/ForCorporatesUiController'
import FooterUiController from './footer/footerUiController'

class IndexUiConoller {

    constructor(globalStore) {

        this.globalStore = globalStore
        this.parentContainer = document.querySelector('#project-main-container')

        this.setupScreens()

    }

    setupScreens() {

        this.popupUiController = new PopupUiController({ globalStore: this.globalStore, parentContainer: this.parentContainer })
        this.enquiryUiController = new EnquiryUiController({ globalStore: this.globalStore, parentContainer: this.parentContainer })
        this.headerUiConoller = new HeaderUiConoller({ globalStore: this.globalStore, parentContainer: this.parentContainer })
        this.landingUiConoller = new LandingUiConoller({ globalStore: this.globalStore, parentContainer: this.parentContainer })
        this.occasssionTypesUiController = new OccasssionTypesUiController({ globalStore: this.globalStore, parentContainer: this.parentContainer })
        this.whyNOTUiController = new WhyNOTUiController({ globalStore: this.globalStore, parentContainer: this.parentContainer })
        this.workprocessUiController = new WorkprocessUiController({ globalStore: this.globalStore, parentContainer: this.parentContainer })
        this.applicationUiController = new ApplicationUiController({ globalStore: this.globalStore, parentContainer: this.parentContainer })
        this.testimonialUiController = new TestimonialUiController({ globalStore: this.globalStore, parentContainer: this.parentContainer })
        this.forCorporatesUiController = new ForCorporatesUiController({ globalStore: this.globalStore, parentContainer: this.parentContainer })
        this.footerUiController = new FooterUiController({ globalStore: this.globalStore, parentContainer: this.parentContainer })

        this.popupUiController.show()
        this.headerUiConoller.show()
        this.landingUiConoller.show()
        this.occasssionTypesUiController.show()
        this.whyNOTUiController.show()
        this.workprocessUiController.show()
        this.applicationUiController.show()
        this.testimonialUiController.show()
        this.forCorporatesUiController.show()
        this.footerUiController.show()
    }

}

export default IndexUiConoller;