import Images from '../../../assets/Images'
import Icon from '../../../assets/Icon'
class FooterUiController {

    constructor({ globalStore, parentContainer }) {

        this.globalStore = globalStore
        this.parentContainer = parentContainer

        this.items = [
            {
                title: "Namma Ooru Taxi",
                details: [
                    {
                        icon: Icon.footer.loc,
                        value: "#8/25, 2nd Floor, Kambar Street, <br />Alandur, Chennai - 600 016",
                        link: '',
                        isnoLink: true
                    },
                    {
                        icon: Icon.footer.call,
                        value: "+91 6384484584",
                        link: 'tel:+916384484584'
                    },
                    {
                        icon: Icon.footer.email,
                        value: "reachus@virtualmaze.co.in",
                        link: 'mailto:reachus@virtualmaze.co.in'
                    },
                    {
                        icon: Icon.footer.web,
                        value: "www.virtualmaze.com",
                        link: 'https://www.virtualmaze.com'
                    },
                ]
            },
            {
                title: "HELP",
                details: [
                    {
                        icon: '',
                        value: "Contact Us",
                        link: 'tel:+916384484584'
                    }
                ]
            },
            {
                title: "DOWNLOAD",
                details: [
                    {
                        icon: Icon.footer.android,
                        img: '',
                        value: "",
                        link: '',
                        isnoLink: true
                    },
                    {
                        icon: undefined,
                        img: Images.customerApp,
                        value: 'Namma Ooru Taxi',
                        link: 'https://play.google.com/store/apps/details?id=com.localcalltaxi.nammaoorutaxi',
                    },
                    {
                        icon: undefined,
                        img: Images.driverApp,
                        value: 'Namma Ooru Taxi <br /> For Drivers',
                        link: 'https://play.google.com/store/apps/details?id=com.virtualmaze.nammaoorutaxi'
                    },
                ]
            },
            {
                title: "FOLLOW",
                details: [
                    {
                        icon: Icon.footer.fb,
                        value: '',
                        link: 'https://www.facebook.com/nammaoorutaxi'
                    }
                ]
            },
        ]
    }


    makeFooterSection(item, index) {
        let container = document.createElement('div')
        container.classList.add('footer-section')
        container.innerHTML = `
            <div class='${index == 0 ? 'footer-section-bigtitle' : 'footer-section-title'}'>${item.title}</div>               
            <div class='footer-section-details'>
            </div>   
        `

        let details_parent = container.querySelector('.footer-section-details')

        let makeDetailsItem = (item) => {

            let container = undefined

            console.log(item.isnoLink, 'isnoLink');

            if (!item.isnoLink) {
                container = document.createElement('a')
                container.href = item.link
                container.target = '_blank'
            }
            else container = document.createElement('div')

            container.classList.add('footer-section-details-itm')

            container.innerHTML = `
                ${item.icon ? item.icon : item.img ? `<img src='${item.img}'/>` : ''}
                <span>${item.value}</span>
            `
            return container
        }

        let details = item.details

        details.forEach((detail, i) => {
            let container = makeDetailsItem(detail)
            details_parent.appendChild(container)
        })


        return container
    }
    eventFooterSection(item, index, container) { }

    renderFooterSections() {
        let parent = this.container.querySelector('.footer-sections')


        parent.innerHTML = ``

        this.items.forEach((item, i) => {

            let container = this.makeFooterSection(item, i)
            parent.appendChild(container)
            this.eventFooterSection(item, i, container)
        })
    }


    makeContainer() {
        let container = document.createElement('div')
        container.classList.add('project-footer-main')
        container.innerHTML = `
            <div class='footer-items'>
                <div class='footer-header'>
                    <a href="#" ><img src='${Images.logo}' title='logo'/></a>
                </div>               
                <div class='footer-sections'>
                </div>               
                <div class='footer-bottom'>
                    COPYRIGHT © 2022 . NAMMA OORU TAXI | VIRTUALMAZE SOFTSYS PRIVATE LIMITED
                </div>               
                      
            </div>               
        `

        return container
    }


    addEvent() {
        this.renderFooterSections()
    }
    show() {

        this.container = this.makeContainer()
        this.parentContainer.appendChild(this.container)
        this.addEvent()
    }


}

export default FooterUiController;