import Images from '../../../assets/Images'
import Icon from '../../../assets/Icon'
class WhyNOTUiController {

    constructor({ globalStore, parentContainer }) {

        this.globalStore = globalStore
        this.parentContainer = parentContainer

    }

    makeWhyNOTItem(item) {
        let { icon, line1, line2 } = item
        let container = document.createElement('div')
        container.classList.add('whynot-section')
        container.innerHTML = `
            <div class='whynot-section-icon'>${icon}</div>
            <div class='whynot-section-title'>${line1}<br/>${line2}</div>
        `
        return container
    }

    renderWhyNOTItems() {
        let Items = [
            [
                {
                    icon: Icon.whynot._1,
                    line1: "Sanitized Driver &",
                    line2: "Cab",
                },
                {
                    icon: Icon.whynot._2,
                    line1: "Transparent",
                    line2: "Pricing",
                },
                {
                    icon: Icon.whynot._3,
                    line1: "Safe and Secure",
                    line2: "Rides",
                },
                {
                    icon: Icon.whynot._4,
                    line1: "Low Cost",
                    line2: "Rides",
                },
                {
                    icon: Icon.whynot._4,
                    line1: "On Demand",
                    line2: "Service",
                }
            ],
            [
                {
                    icon: Icon.whynot._9,
                    line1: "Comfortable",
                    line2: "Rides",
                },
                {
                    icon: Icon.whynot._8,
                    line1: "Doorstep Pickup",
                    line2: "& Drop",
                },
                {
                    icon: Icon.whynot._6,
                    line1: "Scheduled",
                    line2: "Booking",
                },
                {
                    icon: Icon.whynot._5,
                    line1: "Two Way",
                    line2: "Trips",
                }
            ],
            [
                {
                    icon: Icon.whynot._10,
                    line1: "Free Cancellation",
                    line2: "upto 24 Hours",
                },
                {
                    icon: Icon.whynot._11,
                    line1: "Professional",
                    line2: "Drivers",
                },
                {
                    icon: Icon.whynot._12,
                    line1: "24/7 Cab",
                    line2: "Availability",
                }
            ],
        ]

        let itemsParent = this.container.querySelector('.whynot-sections')

        let clear = () => {
            itemsParent.innerHTML = ``
        }

        clear()

        Items.forEach((item, i) => {

            item.forEach(itm => {
                let container = this.makeWhyNOTItem(itm)
                itemsParent.appendChild(container)
            })

        })


    }


    makeContainer() {
        let container = document.createElement('div')
        container.classList.add('project-whynot-main')
        container.innerHTML = `
            <div class='project-whynot-bg'></div>
            <div class='whynot-items'>
               <div class='whynot-title-main'>
                   ${Icon.borderleft}
                   <div class='whynot-title'>
                       Why <span>Namma Ooru Taxi</span>
                   </div>
                   ${Icon.borderright}
               </div>               
               <div class='whynot-desc'>
                   <p>Namma Ooru Taxi offers local taxi, intercity taxi, rental and corporates</p>
               </div>               
               <div class='whynot-sections'></div>                                       
           </div>               
        `

        return container
    }


    addEvent() {
        this.renderWhyNOTItems()
    }
    show() {

        this.container = this.makeContainer()
        this.parentContainer.appendChild(this.container)
        this.addEvent()
    }


}

export default WhyNOTUiController;