import Images from '../../../assets/Images'
import Icon from '../../../assets/Icon'
class ApplicationUiController {

    constructor({ globalStore, parentContainer }) {

        this.globalStore = globalStore
        this.parentContainer = parentContainer

    }
    makeApplicationItem(item) {
        let { icon, title, desc, features, links, appImage } = item
        let container = document.createElement('div')
        container.classList.add('application-section')
        container.innerHTML = `
            <div class='application-section-left'>
                <div class='application-section-image'>
                    <img src='${icon}'/>
                </div>             
                <div class='application-section-title'>
                   ${title}
                </div>             
                <div class='application-section-desc'>${desc}</div>             
                <div class='application-section-features'></div>             
                <div class='application-section-footer'>
                    <div class='application-section-footer-item'>
                       <a target='_blank' href='${links.playstore}'> ${Icon.playstore} </a>
                    </div>             
                </div>             
            </div>
            <div class='application-section-right'>        
                <img src='${appImage}'/>     
            </div>             
        `

        let featuresContainer = container.querySelector('.application-section-features')

        let makeFeatureItem = (feature) => {
            let container = document.createElement('div')
            container.classList.add('application-section-feature')
            container.innerHTML = `
                <span>${feature.icon}</span>
                <span>${feature.name}</span>
            `
            return container
        }

        features.forEach((feature) => {
            let item = makeFeatureItem(feature)
            featuresContainer.appendChild(item)
        })

        return container
    }
    renderApplicationItems() {
        let Items = [

            {
                icon: Images.customerApp,
                title: 'Customer App',
                desc: 'Namma Ooru Taxi® is a cab booking app which allows you to book cab/taxi or auto at low fare. We are providing excellent taxi services in Chennai, Madurai, Coimbatore, Trichy, Salem, Nagercoil, Kanyakumari, Rameswaram and all over Tamil Nadu.',
                features: [],
                links: {
                    playstore: "https://play.google.com/store/apps/details?id=com.localcalltaxi.nammaoorutaxi",
                    appstore: "",
                },
                appImage: Images.customerAppUI
            },
            {
                icon: Images.driverApp,
                title: 'Driver App',
                desc: 'Namma Ooru Taxi® is an app for the taxi and auto drivers who want to get more rides and money. This app is unique among the cab booking apps and more suitable for the drivers who are in cities, towns and villages. It also must be an alternative of normal taxi booking apps.',
                features: [],
                links: {
                    playstore: "https://play.google.com/store/apps/details?id=com.virtualmaze.nammaoorutaxi",
                    appstore: "",
                },
                appImage: Images.driverAppUI
            },


        ]

        let itemsParent = this.container.querySelector('.application-sections')

        itemsParent.innerHTML = ``

        Items.forEach((itm, i) => {

            let container = this.makeApplicationItem(itm)
            itemsParent.appendChild(container)
        })
    }
    makeContainer() {
        let container = document.createElement('div')
        container.classList.add('project-application-main')
        container.innerHTML = `
            <div class='application-items'>
                <div class='application-title-main'>
                    ${Icon.borderleft}
                    <div class='application-title'>
                        Mobile <span>Application</span>
                    </div>
                    ${Icon.borderright}
                </div>               
                <div class='application-sections'>
                </div>             
           </div>               
        `

        return container
    }
    addEvent() {
        this.renderApplicationItems()
    }
    show() {

        this.container = this.makeContainer()
        this.parentContainer.appendChild(this.container)
        this.addEvent()
    }
}

export default ApplicationUiController;