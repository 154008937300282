import Logo from '../assets/images/logo/1.png'
import Logo_light from '../assets/images/logo/logo_light.png'
import LogoSmall from '../assets/images/logo/logosmall.png'
import LandingBanner from '../assets/images/banner/1.png'

import Cityride from '../assets/images/dailyrides/1.png'
import Outstationride from '../assets/images/outstation/1.png'
import Packageride from '../assets/images/rentel/1.png'
import Officeride from '../assets/images/officeride/1.png'

import Chennai from '../assets/images/locations/chennai.jpg'
import Ooty from '../assets/images/locations/ooty.jpg'
import CustomerApp from '../assets/images/notcustomer/1.png'
import DriverApp from '../assets/images/notdriver/1.png'
import CustomerAppUI from '../assets/images/customer.png'
import DriverAppUI from '../assets/images/driver.png'

import BikeType from '../assets/images/fleetbg/bike.png'
import AutoType from '../assets/images/fleetbg/auto.png'
import Hatcback from '../assets/images/fleetbg/hatchback.png'
import Sedan from '../assets/images/fleetbg/sedan.png'
import SUV from '../assets/images/fleetbg/suv.png'

import Popup_Banner_1 from '../assets/images/popupbanners/popup_banner.jpeg'
import Popup_Banner_2 from '../assets/images/popupbanners/popup_banner2.png'
import Contact_us_svg from '../assets/images/contactus.png'

import Booking_local from '../assets/images/booking/local.png'
import Booking_outstation from '../assets/images/booking/outstation.jpg'
import Booking_package from '../assets/images/booking/package.png'

import Booking_local_icon from '../assets/images/booking/local_icon.png'
import Booking_package_icon from '../assets/images/booking/package_icon.png'
import Booking_outstation_icon from '../assets/images/booking/outstation_icon.png'
import Booking_Banner from '../assets/images/booking/banner.svg'

const Images = {
    logo: Logo,
    logo_light: Logo_light,
    logosmall: LogoSmall,
    landingBanner: LandingBanner,
    cityride: Cityride,
    outstationride: Outstationride,
    packageride: Packageride,
    officeride: Officeride,
    locationfrom: Chennai,
    locationto: Ooty,
    customerApp: CustomerApp,
    customerAppUI: CustomerAppUI,
    driverApp: DriverApp,
    driverAppUI: DriverAppUI,
    bikeType: BikeType,
    autoType: AutoType,
    hatcback: Hatcback,
    sedan: Sedan,
    suv: SUV,
    popup_banner_1: Popup_Banner_1,
    popup_banner_2: Popup_Banner_2,
    contact_us_svg: Contact_us_svg,

    booking_local: Booking_local,
    booking_outstation: Booking_outstation,
    booking_package: Booking_package,

    booking_local_icon: Booking_local_icon,
    booking_package_icon: Booking_package_icon,
    booking_outstation_icon: Booking_outstation_icon,
    booking_banner: Booking_Banner,

}

export default Images