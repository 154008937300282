import Images from '../../../assets/Images'
import Icon from '../../../assets/Icon'
class OccasssionTypesUiController {

    constructor({ globalStore, parentContainer }) {

        this.globalStore = globalStore
        this.parentContainer = parentContainer

    }


    makeContainer() {
        let container = document.createElement('div')
        container.classList.add('project-occasssion-main')
        container.innerHTML = `
            <div class='occasssion-items'>
                <div class='occasssion-title-main'>
                    ${Icon.borderleft}
                    <div class='occasssion-title'>
                        Car for <span>Every Occassion</span>
                    </div>
                    ${Icon.borderright}
                </div>               
                <div class='occasssion-desc'>
                    <p>Namma Ooru Taxi offers local taxi, intercity taxi, rental and corporates</p>
                </div>               
                <div class='occasssion-sections'>
                    <div class='occasssion-section'>
                        <div class='occasssion-section-left'>
                            <div class='occasssion-section-title'>
                                City Rides
                                <span class='active-bar'></span>
                            </div>
                            <div class='section-desc-items'>
                                <div class='section-desc-itm'>
                                    <span>${Icon.ac}</span>
                                    <p>AC Cabs</p>
                                </div>
                                <div class='section-desc-itm'>
                                    <span>${Icon.money}</span>
                                    <p>Affordable Price</p>
                                </div>
                                <div class='section-desc-itm'>
                                    <span>${Icon.drop}</span>
                                    <p>Doorstep Pickup & Drop</p>
                                </div>
                            </div>
                            <div class='occasssion-section-footer'>
                                <span>${Icon.readmore}</span>
                                <p>Read More</p>
                            </div>
                        </div>
                        <div class='occasssion-section-right'>
                        <img src='${Images.cityride}' />
                        </div>
                    </div>    
                    <div class='occasssion-section'>
                        <div class='occasssion-section-left'>
                            <div class='occasssion-section-title'>
                                Outstation Rides
                                <span class='active-bar'></span>
                            </div>
                            <div class='section-desc-items'>
                                <div class='section-desc-itm'>
                                    <span>${Icon.way}</span>
                                    <p>One Ways Fares</p>
                                </div>
                                <div class='section-desc-itm'>
                                    <span>${Icon.shedule}</span>
                                    <p>Scheduled Booking</p>
                                </div>
                                <div class='section-desc-itm'>
                                    <span>${Icon.safe}</span>
                                    <p>Safe & Secure</p>
                                </div>
                            </div>
                            <div class='occasssion-section-footer'>
                                <span>${Icon.readmore}</span>
                                <p>Read More</p>
                            </div>
                        </div>
                        <div class='occasssion-section-right'>
                        <img src='${Images.outstationride}' />
                        </div>
                    </div>    
                    <div class='occasssion-section'>
                        <div class='occasssion-section-left'>
                            <div class='occasssion-section-title'>
                            Package Rides
                                <span class='active-bar'></span>
                            </div>
                            <div class='section-desc-items'>
                                <div class='section-desc-itm'>
                                    <span>${Icon.hourly}</span>
                                    <p>Hourly Package</p>
                                </div>
                                <div class='section-desc-itm'>
                                    <span>${Icon.way}</span>
                                    <p>Multiple Stops</p>
                                </div>
                                <div class='section-desc-itm'>
                                    <span>${Icon.available}</span>
                                    <p>24/7 Cab Availability</p>
                                </div>
                            </div>
                            <div class='occasssion-section-footer'>
                                <span>${Icon.readmore}</span>
                                <p>Read More</p>
                            </div>
                        </div>
                        <div class='occasssion-section-right'>
                        <img src='${Images.packageride}' />
                        </div>
                    </div>    
                    <div class='occasssion-section'>
                        <div class='occasssion-section-left'>
                            <div class='occasssion-section-title'>
                                Office Rides 
                                <span class='active-bar'></span>
                            </div>
                            <div class='section-desc-items'>
                                <div class='section-desc-itm'>
                                    <span>${Icon.livetracking}</span>
                                    <p>Live Tracking</p>
                                </div>
                                <div class='section-desc-itm'>
                                    <span>${Icon.addDriver}</span>
                                    <p>Vendor Request</p>
                                </div>
                                <div class='section-desc-itm'>
                                    <span>${Icon.assign}</span>
                                    <p>Assign Employees / Drivers</p>
                                </div>
                            </div>
                            <div class='occasssion-section-footer'>
                                <span>${Icon.readmore}</span>
                                <p>Read More</p>
                            </div>
                        </div>
                        <div class='occasssion-section-right'>
                        <img src='${Images.officeride}' />
                        </div>
                    </div>    
                </div>             
            </div>               
        `

        return container
    }


    addEvent() { }
    show() {

        this.container = this.makeContainer()
        this.parentContainer.appendChild(this.container)
        this.addEvent()
    }


}

export default OccasssionTypesUiController;