import Images from '../../../assets/Images'
class HeaderUiConoller {

    constructor({ globalStore, parentContainer }) {

        this.globalStore = globalStore
        this.parentContainer = parentContainer

    }

    makeContainer() {
        let container = document.createElement('div')
        container.classList.add('project-header-main')
        container.innerHTML = `
            <div class='header-items'>
                <div class='header-items-left'>
                    <div class='header-items-left'>
                        <div class='header-left-item'>
                        <a href="#" ><img src='${Images.logo}' title='logo'/></a>
                        </div>
                    </div>
                </div>
                <div class='header-items-right'>
                    <div class='header-right-item right-item-active'>
                        <span class="item-text">Home</span>
                        <span class="active-bar"></span>
                    </div>
                    <div class='header-right-item'>
                        <a href='/booking.html'><span class="item-text">Booking</span></a>
                        <span class="active-bar"></span>
                    </div>
                    <div class='header-right-item'>
                        <span class="item-text"><a target='_blank' href="https://api.whatsapp.com/send?phone=916384484584&text=Hello%20Namma%20Ooru%20Taxi">Whats App</a></span>
                        <span class="active-bar"></span>

                    </div>
                    <div class='header-right-item'>
                        <span class="item-text"><a href="tel:+916384484584">+91 6384484584</a></span>
                        <span class="active-bar"></span>

                    </div>
                    <div class='header-right-auth-buttons'>
                        <a href='https://play.google.com/store/apps/details?id=com.localcalltaxi.nammaoorutaxi&pli=1' target='_blank'>
                            <div class='header-right-register-btn'>Register</div>
                        </a>
                        <div class='header-right-login-btn' id='header-enquiry-btn' >Enquiry</div>
                    </div>
                </div>
                <div class='header-items-right-mobile'>
                    <div class='header-right-auth-buttons'>
                        <a href='https://play.google.com/store/apps/details?id=com.localcalltaxi.nammaoorutaxi&pli=1' target='_blank'>
                            <div class='header-right-register-btn'>Register</div>
                        </a>
                        <div class='header-right-login-btn' id='mb-header-enquiry-btn'>Enquiry</div>
                    </div>
                    <div class='header-right-icons' id='mobile-menu-toogle-btn'>
                        <i class='bx bx-menu'></i>
                    </div>
                    <div class='header-mobile-menu-container' id='header-mobile-menu-container'>
                        <div class='header-mobile-menu-item'>
                            <span>
                                <a href="#">
                                    Home
                                </a>
                            </span>
                        </div>
                        <div class='header-mobile-menu-item'>
                            <span>
                                <a href="/booking.html">
                                    Booking
                                </a>
                            </span>
                        </div>
                        <div class='header-mobile-menu-item'>
                            <span>
                                <a target='_blank' href="https://api.whatsapp.com/send?phone=916384484584&text=Hello%20Namma%20Ooru%20Taxi">
                                    Whats App
                                </a>
                            </span>
                        </div>
                        <div class='header-mobile-menu-item'>
                            <span>
                                <a href="tel:+916384484584">
                                    +91 6384484584
                                </a>
                            </span>
                        </div>
                        <div class='header-mobile-menu-item'>
                            <a href='https://play.google.com/store/apps/details?id=com.localcalltaxi.nammaoorutaxi&pli=1' target='_blank'>
                                <div class='header-mobile-register-btn'>Register</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        `

        return container
    }


    addEvent() {

        let enquiry_btn = this.container.querySelector('#header-enquiry-btn')
        let mb_enquiry_btn = this.container.querySelector('#mb-header-enquiry-btn')
        let mobile_menu_btn = this.container.querySelector('#mobile-menu-toogle-btn')
        let mobile_menu_container = this.container.querySelector('#header-mobile-menu-container')

        let active = true

        mobile_menu_btn.addEventListener('click', (e) => {

            if (active) {
                mobile_menu_container.style.display = 'flex'
                mobile_menu_btn.innerHTML = `<i class='bx bx-x'></i>`
                active = false
            }
            else {
                mobile_menu_container.style.display = 'none'
                mobile_menu_btn.innerHTML = `<i class='bx bx-menu'></i>`
                active = true
            }

        })

        window.addEventListener('click', (e) => {

            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(mobile_menu_btn) && !path.includes(mobile_menu_container)) {
                mobile_menu_container.style.display = 'none'
                mobile_menu_btn.innerHTML = `<i class='bx bx-menu'></i>`
                active = true
            }
        })

        enquiry_btn.addEventListener('click', (e) => {

            this.globalStore.uiConoller.enquiryUiController.show()

        })

        mb_enquiry_btn.addEventListener('click', (e) => {
            this.globalStore.uiConoller.enquiryUiController.show()
        })

    }
    show() {

        this.container = this.makeContainer()
        this.parentContainer.appendChild(this.container)
        this.addEvent()
    }


}

export default HeaderUiConoller;