import MapConfig from './config/Map';

class Search {


    constructor(globalStore) {

        this.globalStore = globalStore

        this.search = this.search.bind(this)
    }



    async search({ text, from = 0, limit = 6, signal }) {


        // location = location || this.globalStore.map.userLocation

        let response = await fetch(

            /* Root Url */

            MapConfig['searchApiUrl'] +

            /* Search key */

            '?q=' + text +

            /* Location to search for */

            // (location ? `&lat=${location[0]}&lon=${location[1]}` : '') +

            /* Access Token */

            `&access_token=${MapConfig['searchAccessToken']}` + `&from=${from}&limit=${limit}`,

            {
                signal
            }

        )

        let jsonRes = await response.json()

        let { features } = jsonRes



        return features

    }


}





export default Search