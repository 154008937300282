
import Store from "./store/store"
import IndexUiConoller from "./UiControllers/index"
import Search from "./Search"

export default {

    async init() {

        let store = new Store()

        let search = new Search(store)
        store.add('search', search)

        let UiConoller = new IndexUiConoller(store)
        store.add('uiConoller', UiConoller)

    },

}