import Images from '../../../assets/Images'
import Icon from '../../../assets/Icon'
class WorkprocessUiController {

    constructor({ globalStore, parentContainer }) {

        this.globalStore = globalStore
        this.parentContainer = parentContainer

    }

    makeWorkprocessItem(item, index) {
        let { icon, line1, line2 } = item
        let container = document.createElement('div')
        container.classList.add('workprocess-section')
        container.innerHTML = `
            <div class='workprocess-section-index'>${index}</div>
            <div class='workprocess-section-icon'>${icon}</div>
            <div class='workprocess-section-title'>${line1}<br/>${line2}</div>
        `
        return container
    }

    renderWorkprocessItems() {
        let Items = [

            {
                icon: Icon.workprocess._1,
                line1: "Select Pickup &",
                line2: "Drop Location",
            },
            {
                icon: Icon.workprocess._2,
                line1: "Compare the prices ",
                line2: "from multiple Taxis",
            },
            {
                icon: Icon.workprocess._3,
                line1: "Confirm and book",
                line2: "the taxi you prefer",
            },
            {
                icon: Icon.workprocess._4,
                line1: "Your request will be",
                line2: "sent to nearby drivers",
            },
            {
                icon: Icon.workprocess._5,
                line1: "Driver will accept",
                line2: "your request",
            },
            {
                icon: Icon.workprocess._6,
                line1: "Wait for Driver to",
                line2: "reach you location",
            },
            {
                icon: Icon.workprocess._7,
                line1: "Enter OTP to",
                line2: "begin ride",
            },
            {
                icon: Icon.workprocess._8,
                line1: "You will be reached",
                line2: "your destination",
            },
            {
                icon: Icon.workprocess._9,
                line1: "Pay by cash, card or",
                line2: "UPI to end ride.",
            },
            {
                icon: Icon.workprocess._10,
                line1: "Please share your",
                line2: "experience",
            },

        ]

        let itemsParent = this.container.querySelector('.workprocess-sections')

        itemsParent.innerHTML = ``

        Items.forEach((itm, i) => {

            let itemIndex = String(i + 1)
            itemIndex = itemIndex.length < 2 ? '0' + itemIndex : itemIndex;

            let container = this.makeWorkprocessItem(itm, itemIndex)
            itemsParent.appendChild(container)
        })
    }


    makeContainer() {
        let container = document.createElement('div')
        container.classList.add('project-workprocess-main')
        container.innerHTML = `
            <div class='project-workprocess-bg'></div>
            <div class='workprocess-items'>
               <div class='workprocess-title-main'>
                   ${Icon.borderleft}
                   <div class='workprocess-title'>
                       How it <span>Works?</span>
                   </div>
                   ${Icon.borderright}
               </div>               
               <div class='workprocess-desc'>
                   <p>Get a Taxi in under 2 Minutes</p>
               </div>               
               <div class='workprocess-sections'></div>             
           </div>               
        `

        return container
    }


    addEvent() {
        this.renderWorkprocessItems()
    }
    show() {

        this.container = this.makeContainer()
        this.parentContainer.appendChild(this.container)
        this.addEvent()
    }


}

export default WorkprocessUiController;