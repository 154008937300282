import Images from '../../../assets/Images'
import Icon from '../../../assets/Icon'

class ForCorporatesUiController {

    constructor({ globalStore, parentContainer }) {

        this.globalStore = globalStore
        this.parentContainer = parentContainer

    }

    makeBriefDetailsItem(item) {
        let { icon, line1, line2 } = item
        let container = document.createElement('div')
        container.classList.add('forcorporates-detailsbrief-itm')
        container.innerHTML = `
            <div class='forcorporates-detailsbrief-icon'>${icon}</div>
            <div class='forcorporates-detailsbrief-value'>${line1}<br/>${line2}</div>
        `
        return container
    }

    renderBriefDetailsItems() {
        let Items = [

            {
                icon: Icon.forCorporats._1,
                line1: "24/7",
                line2: "IT Rides",
            },
            {
                icon: Icon.forCorporats._2,
                line1: "Free",
                line2: "Attachments",
            },
            {
                icon: Icon.forCorporats._3,
                line1: "Weekly",
                line2: "Payments",
            },
            {
                icon: Icon.forCorporats._4,
                line1: "Pickup &",
                line2: "Drop Only",
            },
            {
                icon: Icon.forCorporats._5,
                line1: "Deposit to",
                line2: "Bank Account",
            },
            {
                icon: Icon.forCorporats._6,
                line1: "High",
                line2: "Earnings",
            },
            {
                icon: Icon.forCorporats._7,
                line1: "10%",
                line2: "Commission",
            },

        ]

        let itemsParent = this.container.querySelector('.forcorporates-detailsbrief')

        itemsParent.innerHTML = ``

        Items.forEach((item, i) => {

            let container = this.makeBriefDetailsItem(item)
            itemsParent.appendChild(container)

        })
    }

    makeContainer() {
        let container = document.createElement('div')
        container.classList.add('project-forcorporates-main')
        container.innerHTML = `
            <div class='project-forcorporates-bg'></div>
            <div class='forcorporates-items'>
               <div class='forcorporates-title-main'>
                   ${Icon.borderleft}
                   <div class='forcorporates-title'>
                    Namma Ooru Taxi <span>For Corporates</span>
                   </div>
                   ${Icon.borderright}
               </div>               
               <div class='forcorporates-desc'>
                   <p>Avail exlusive benefits by registering taxi for corporates</p>
               </div>               
               <div class='forcorporates-details'>
                    <div class='forcorporates-details-itm'>
                        <div class='forcorporates-details-itm-bg'></div>
                        <div class='forcorporates-details-itm-left'>
                            <p>Sedan / Hatchback</p>
                            <div>
                                <span>₹<strong>22</strong>/Km</span>
                            </div>
                        </div>
                        <div class='forcorporates-details-itm-right'>
                            ${Icon.dailyridetaxi}
                        </div>
                    </div>
                    <div class='forcorporates-details-itm'>
                        <div class='forcorporates-details-itm-bg'></div>
                        <div class='forcorporates-details-itm-left'>
                            <p>SUV</p>
                            <div>
                                <span>₹<strong>28</strong>/Km</span>
                            </div>
                        </div>
                        <div class='forcorporates-details-itm-right'>
                            ${Icon.officeridetaxi}
                        </div>
                    </div>
               </div>
               <div class='forcorporates-detailsbrief'>
                    <div class='forcorporates-detailsbrief-itm'>
                        <div class='forcorporates-detailsbrief-icon'>
                            ${Icon.forCorporats._1}
                        </div>
                        <div class='forcorporates-detailsbrief-value'>
                            <span>24/7</span>
                            <span>IT Rides</span>
                        </div>
                    </div>
                
               </div>
               <div class='forcorporates-buttons'>
                    <a href="tel:+916384484584">
                        <div class='forcorporates-button'>
                            <div class='forcorporates-btn-icon'>
                                ${Icon.call}
                            </div>
                            <div class='forcorporates-btn-value'>
                                <p>Call us Now</p>
                                <span>+91 6384484584</span>
                            </div>
                        </div>
                    </a>            
               </div>             
           </div>               
        `
        return container
    }


    addEvent() {
        this.renderBriefDetailsItems()
    }
    show() {

        this.container = this.makeContainer()
        this.parentContainer.appendChild(this.container)
        this.addEvent()
    }


}

export default ForCorporatesUiController;